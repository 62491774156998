<template>
	<div class="list-item dF aC jC" @click="$emit('select', item)" :data-file-id="item.id"
		style="height: 65px; width: 65px; text-align: center;">
		<!-- <a-icon :type="item.icon" style="font-size: 40px; margin-top: 10px;" /> -->
		<img :src="`${resURL}${item.path}`" />
	</div>
</template>

<script>
const tld = window.location.href.includes('bildhive.com') ? 'com' : 'dev'
export default {
	props: ['item', 'test'],
	data() {
		return {
			resURL: `https://res.bildhive.${tld}`
		}
	},
	watch: {


	},
	methods: {

	},
	created() {
	}

}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
@import "~bh-mod/styles/filemanager/files.css";

.image-check-box .ant-checkbox {
	float: right;
	right: 3px;
	top: 3px;
	z-index: 100;
}
</style>
<style lang="scss" scoped>
.image-box {
	display: table;
	width: 65px;
	height: 65px;
	text-align: center;
	vertical-align: top;
	cursor: pointer;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50%;
}

.file-icon {
	font-family: Arial, Tahoma, sans-serif;
	font-weight: 300;
	display: inline-block;
	background: #797998;
	background-image: initial;
	background-position-x: initial;
	background-position-y: initial;
	background-size: initial;
	background-repeat-x: initial;
	background-repeat-y: initial;
	background-attachment: initial;
	background-origin: initial;
	background-clip: initial;
	background-color: rgb(121, 121, 152);
	position: relative;
	text-align: left;
	-webkit-font-smoothing: antialiased;
}

.list-item:hover {
	border: solid 2px;
	border-color: var(--orange);
	cursor: pointer;

}

.list-item {
	padding: 0 !important;
}

img {
	object-fit: contain;
	max-width: 100% !important;
	max-height: 100%;
}

.file-info__name {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
</style>
