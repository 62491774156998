<template>
	<div>

		<a-modal :zIndex="1001" centered :title="'Icon Library'" :visible="dialog.show" :footer="null" @cancel="onClose"
			:width="'70%'" class="file-selector-box">
			<div class="dF mb-4">

				<!-- Left side of File Manager -->
				<div v-if="windowWidth >= 1205" style="height: 100%; padding-right: 25px; width: 200px;">
					<div style="height:465px; overflow: auto;">
						<h6>Filter</h6>
						<template v-for="(fold, foldI) in iconFolders">
							<div :class="currentIconFolder === fold.value ? 'text-primary' : ''"
								@click="currentIconFolder = fold.value" class="folder-name" :key="fold.value + foldI"><i
									class="fa fa-folder mr-3 ml-3" />{{ fold.label }}</div>
						</template>
					</div>
				</div>

				<a-row :gutter="16" style="flex: 1;">

					<!-- Right side of File Manager -->
					<a-col :span="24">

						<!-- Top bar options -->
						<a-row v-if="windowWidth >= 1205 && false" :gutter="16" class="mb-4">
							<a-col :span="6">
								<a-select size="large" v-model="selection" style="width: 100%;">
									<a-select-option value="all">
										All Styles
									</a-select-option>
									<a-select-option value="filled">
										Filled
									</a-select-option>
									<a-select-option value="coloured">
										Coloured
									</a-select-option>
								</a-select>
							</a-col>

							<a-col :span="18" class="dF" v-if="false">
								<div style="display:flex;justify-content:flex-end; width: 100%;">
									<a-auto-complete size="large" placeholder="Search for an icon..."
										style="width: 50%;" :dropdown-match-select-width="false"
										:dropdown-style="{ width: '300px' }" option-label-prop="value"
										@search="handleSearch">
										<template slot="dataSource">
											<a-select-option @click="optionClicked(file.id, file.folder)"
												v-for="(file, fileI) in searchResult" :key="fileI" :value="file.name">
												<div class="dF aC">
													<span class="mr-2"><img
															style="width:30px; height:30px; object-fit:cover;"
															:src="`${file.url}`" /></span>
													<div class="f1">
														<div><strong>{{ file.name }}</strong></div>
													</div>
												</div>
											</a-select-option>
										</template>

										<a-input>
											<a-icon slot="suffix" type="search" />
										</a-input>
									</a-auto-complete>
								</div>
							</a-col>
						</a-row>

						<!-- Top bar options for ipad -->
						<a-row v-else-if="false" :gutter="16" class="mb-4">
							<a-col :span="4">
								<a-select :default-value="'Main'"
									:value="currentFolder === 'Toilets' ? 'Toilets' : currentFolder"
									style="width: 100%;">
									<template v-for="(fold, foldI) in folders">
										<a-select-option v-if="fold.label !== 'All Files'" :value="fold.label"
											:key="fold.value">
											<div @click="changeCurrentFolder(fold)" class="folder-name"
												:key="fold + foldI" :value="fold"><i class="fa fa-folder mr-3 ml-3" />{{
												fold }}</div>
										</a-select-option>
									</template>
								</a-select>
							</a-col>

							<a-col :span="5">
								<a-select default-value="all" style="width: 100%;">
									<a-select-option value="all">
										All Styles
									</a-select-option>
									<a-select-option value="filled">
										Filled
									</a-select-option>
									<a-select-option value="coloured">
										Coloured
									</a-select-option>
								</a-select>
							</a-col>

							<a-col :span="7" class="dF">
								<a-auto-complete placeholder="Search for an icon..." style="width: 100%;"
									:dropdown-match-select-width="false" :dropdown-style="{ width: '300px' }"
									option-label-prop="value" @search="handleSearch">
									<template slot="dataSource">
										<a-select-option @click="optionClicked(file.id, file.folder)"
											v-for="(file, fileI) in searchResult" :key="fileI" :value="file.name">
											<div class="dF aC">
												<span class="mr-2"><img
														style="width:30px; height:30px; object-fit:cover;"
														:src="`${file.url}`" /></span>
												<div class="f1">
													<div><strong>{{ file.name }}</strong></div>
												</div>
											</div>
										</a-select-option>
									</template>

									<a-input>
										<a-icon slot="suffix" type="search" />
									</a-input>
								</a-auto-complete>
							</a-col>

						</a-row>

						<!-- Media Files -->
						<a-row :gutter="16">

							<a-col :span="windowWidth >= 1205 ? 18 : 17">
								<div class="imageScroll" style="height:400px; overflow: auto;">
									<a-row :gutter="16" type="flex">
										<a-col v-for="(item, index) in iconFiles" :key="`ffff${index}`" class="mb-3"
											style="text-align: center;">
											<IconHolder :item="item" :active="currentIcon.id === item.id"
												@select="currentIcon = item" />
											<div
												style="max-width: 65px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; font-size:12px;">
												{{ item.name }}
											</div>
										</a-col>
									</a-row>
								</div>
							</a-col>

							<a-col v-if="currentIcon" :span="windowWidth >= 1205 ? 6 : 7" style="height: 250px;">
								<div style="border: 1px solid #E4E7EB; height: 100%;">
									<ColorPicker
										:defaults="dialog.themeColors && dialog.themeColors.length ? dialog.themeColors : []"
										:recent-text="dialog.themeColors && dialog.themeColors.length ? 'Theme Colors' : 'Default Colors'"
										v-model="colorize" class="color-picker-absolute" v-if="dialog.colorized" />
									<div v-html="currentIcon.data" style="height:100%;"
										class="dF jC aC icon-preview-holder" :style="`color:${colorize}`" />
								</div>
								<h5 class="mt-3 text-md">{{ currentIcon.name }}</h5>

								<a-radio-group :default-value="2" @change="changeThickness"
									v-if="isStroke && dialog.stroke" :key="currentIcon.id">
									<a-radio-button :value="1">
										Thin
									</a-radio-button>
									<a-radio-button :value="2">
										Medium
									</a-radio-button>
									<a-radio-button :value="3">
										Thick
									</a-radio-button>
								</a-radio-group>

							</a-col>

							<a-col v-else :span="windowWidth >= 1205 ? 6 : 7" style="height: 250px;">
								<div style="border: 1px solid #E4E7EB; height: 100%;"> </div>
							</a-col>

							<a-col :span="24" style="text-align:end">
								<a-button @click="$store.commit('CLOSE_LIBRARY')" class="mr-3"
									size="large">CANCEL</a-button>
								<a-button @click="submit" :disabled="!currentIcon.id" size="large"
									type="primary">SELECT</a-button>
							</a-col>
						</a-row>

					</a-col>

				</a-row>

			</div>

		</a-modal>


	</div>
</template>

<script>
import axios from 'axios'
import { getBase64 } from 'bh-mod'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import FileUpload from './upload'
import ColorPicker from 'bh-mod/components/common/ColorPicker'
import IconHolder from './IconHolder'
const tld = window.location.href.includes('bildhive.com') ? 'com' : 'dev'
export default {
	components: {
		FileUpload,
		vueCustomScrollbar,
		IconHolder,
		ColorPicker,
		VNodes: {
			functional: true,
			render: (h, ctx) => ctx.props.vnodes,
		},
	},
	data() {
		return {
			tld,
			serviceURL: 'https://services.bildhive.' + tld,
			thickness: 2,
			//Icon Stuffs
			currentIcon: {},
			iconCats: {},
			currentIconFolder: '__all__',
			colorize: '#000000',
			sort: 'latest',
			submitImage: {},
			link: false,
			shareLink: '',
			showOptions: false,
			searchResult: [],
			checkbox: '',
			showImage: false,
			selectImage: {},
			filter: '',
			previewImage: {
				type: '',
				url: '',
				show: false
			},
			currentFilesShowing: [],
			files: [],
			currentFolder: 'Toilets',
			windowWidth: window.innerWidth,

			folders: [
				'Toilets',
				'Food',
				'Stores',
				'Activities',
				'Transport',
				'Health',
				'Entertainment',
				'Services',
				'Business',
				'Government',
				'Religious',
				'Marker Icons'
			],

			iconGroups: {
				Toilets: [{ icon: 'area-chart', title: 'Area Chart' }, { icon: 'pie-chart', title: 'Pie Chart' }],
				Food: [{ icon: 'delete', title: 'Delete' }, { icon: 'edit', title: 'Edit' }],
				Stores: [{ icon: 'fall', title: 'Fall' }, { icon: 'rise', title: 'Rise' }],
				Activities: [{ icon: 'line', title: 'Line' }, { icon: 'more', title: 'More' }],
				Transport: [{ icon: 'plus', title: 'Plus' }, { icon: 'minus', title: 'Minus' }],
				Health: [{ icon: 'wifi', title: 'Wifi' }, { icon: 'upload', title: 'Upload' }],
				Entertainment: [{ icon: 'user-add', title: 'User Add' }, { icon: 'user-delete', title: 'User Delete' }],
				Services: [{ icon: 'safety', title: 'Safety' }, { icon: 'robot', title: 'Robot' }],
				Business: [{ icon: 'setting', title: 'Setting' }, { icon: 'shop', title: 'Shop' }],
				Government: [{ icon: 'read', title: 'Read' }, { icon: 'save', title: 'Save' }],
				Religious: [{ icon: 'layout', title: 'Layout' }, { icon: 'like', title: 'Like' }],
				'Marker Icons': [{ icon: 'align-left', title: 'Align Left' }, { icon: 'align-right', title: 'Align Right' }],
			},

			selection: 'all'

		}
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth
		}
	},
	watch: {
		currentIconFolder(val) {
			this.scrollToTop()
		},
		currentIcon(val) {
			this.thickness = 2
			console.log('THIS THICKNESSS', this.getSVGAttrs(val.data))
		},
		allFiles() {
			console.log('ALL FILES WATCH RAN', this.allFiles)
		},
		sort(val) {
			if (val == 'newest') {
				console.log('newww')
			} else {
				console.log('late')
			}
		},
		'dialog.show'(val) {
			if (val) {
				this.$store.commit('LOAD')
				if (this.dialog.type === 'icons') {
					let url = `${this.serviceURL}/list/svgicons`
					axios.post(url).then(({ data }) => {
						this.iconCats = data
					}).catch(err => {
						this.$message.error(this.$err(err))
					}).finally(() => {
						this.$store.commit('LOAD', false)
					})
				}
			} else {

			}
		},
		showMedia(val) {

			let filter = this.dialog.type

			if (val) {

				let self = this

				this.$api.get('/upload/files?instance=' + this.instance.id).then(({ data }) => {
					let removeIDs = []
					Object.entries(data).forEach(([dirName, files]) => {
						data[dirName] = files.filter(self.filterFiles)
						if (data[dirName].length === 0) delete data[dirName]
					})
					this.FILE_IT(data)
				}).catch(err => {
					this.$message.error(this.$err(err))
				})

			} else {
				this.showImage = false
				this.checkbox = ''
				this.sort = 'latest'
			}
			this.filter = filter

		}

	},
	computed: {
		isStroke() {
			return this.currentIcon && this.currentIcon.data && this.currentIcon.data.includes('data-strk')
		},
		iconFolders() {
			return [{ label: 'All Icons', value: '__all__' }, ...Object.keys(this.iconCats).map(name => ({ label: name, value: name }))]

		},
		iconFiles() {
			if (this.currentIconFolder === '__all__') return this.allIcons
			else return this.iconCats[this.currentIconFolder] && this.iconCats[this.currentIconFolder].icons
			return []
		},
		allIcons() {
			let icons = []
			Object.values(this.iconCats).forEach(d => {
				icons = [...icons, ...d.icons]
			})
			return icons
		},
		dialog() {
			return this.$store.state.library.librarySelect
		},
		fileType() {
			return 'all'
		},
		instance() {
			return this.$store.state.instance
		},
		allFiles() {

			let files = []

			Object.values(this.files).forEach(array => {
				files = [...files, ...array]
			})

			return files

		},
		searchFiles() {
			let storeFiles = this.allFiles
			let fileList = []
			storeFiles.forEach(file => {
				if (fileList.findIndex(x => x.name === file.name) == -1) fileList.push(file)
			})

			return fileList
		},
	},
	methods: {
		clearInput(input) {
			let output = input.replace(new RegExp('([\r\n\t]|\\s{2,})', 'g'), '');

			output = output.replace(new RegExp('(<)(.*?)(xml |dtd)(.*?)(>)', 'g'), '');

			output = output.replace(new RegExp('(<g></g>)', 'g'), '');
			output = output.replace(new RegExp('(<g> </g>)', 'g'), '');
			output = output.replace(new RegExp('(<g>  </g>)', 'g'), '');
			output = output.replace(new RegExp('(<defs></defs>)', 'g'), '');

			output = output.replace(new RegExp('((<!--)(.*?)(-->))', 'g'), '');
			output = output.replace(new RegExp('(<title>(.*?)</title>)', 'g'), '');
			output = output.replace(new RegExp('(<desc>(.*?)</desc>)', 'g'), '');

			output = output.replace(new RegExp('( sketch:type="(.*?)")', 'g'), '');
			output = output.replace(new RegExp('( id="(.*?)")', 'g'), '');

			return output;
		},
		applyStyle(svg, colors) {
			svg = this.clearInput(svg)

			let svgHeadRx = new RegExp('(<style)(.*?)(e>)', 'g');
			styleTag = svgHeadRx.exec(svg)[0]

			let newSvg = svg

			if (styleTag) {

				let classes = []

				if (styleTag.includes('.st0')) classes.push('st0')
				if (styleTag.includes('.st1')) classes.push('st1')
				if (styleTag.includes('.st3')) classes.push('st2')

				let length = classes.length
				console.log(`${length} colors found`)

				let newStyle = '<style type="text/css">'

				classes.forEach((cl, clI) => {

					if (colors[cl]) {
						let currItemRx = new RegExp(`(.${cl})(.*?)(})`, 'g');
						let currString = currItemRx.exec(styleTag)[0]

						newStyle += currString.replace(new RegExp('(fill:#)(.*?)(})', 'g'), `fill:#${colors[cl]}}`);

					}


				})
				newStyle += '</style>'
				newSvg = svg.replace(new RegExp('(<style)(.*?)(e>)'), newStyle)
				console.log('SVGGGGGGGGG', newSvg)
				console.log('newStyle', newStyle)

			}
			return newSvg

		},
		changeAttrs(attrsObj, newAttrsObj) {

			console.log('nEWATTR', newAttrsObj)

			for (var key in newAttrsObj) {
				var oldWidth, newWidth, oldHeight, newHeight;

				if (key === 'width') {
					oldWidth = parseFloat(attrsObj['width']);
					newWidth = parseFloat(newAttrsObj['width']);
					oldHeight = parseFloat(attrsObj['height']);
					newHeight = newWidth / oldWidth * oldHeight;

					attrsObj['height'] = newHeight + 'px';
					attrsObj[key] = newAttrsObj[key] + 'px';
				} else if (key === 'height') {
					oldHeight = parseFloat(attrsObj['height']);
					newHeight = parseFloat(newAttrsObj['height']);

					oldWidth = parseFloat(attrsObj['width']);
					newWidth = newHeight / oldHeight * oldWidth;

					attrsObj['width'] = newWidth + 'px';
					attrsObj[key] = newAttrsObj[key] + 'px';
				}
			}

			return attrsObj;

		},
		getSVGBody(input) {
			return input.replace(new RegExp('(<svg|</svg)(.*?)(>)', 'g'), '');
		},
		rebuildSvgHead(input, newAttrsObj) {
			var out = '';

			var svgKeys = Object.keys(newAttrsObj)

			var attrsObj = this.getSVGAttrs(input);

			if (newAttrsObj) {
				// attrsObj = this.changeAttrs(attrsObj, newAttrsObj);
				attrsObj = { ...attrsObj, ...newAttrsObj }
			}

			for (var i = 0; i < svgKeys.length; i++) {
				var key = svgKeys[i];
				if (attrsObj.hasOwnProperty(key)) {
					out += ' ' + key + '="' + attrsObj[key] + '"';
				}
			}
			out = '<svg' + out + '>';

			return out;
		},
		getSVGAttrs(input) {
			if (!input) {
				return {};
			}
			var svgHeadRx = new RegExp('(<svg)(.*?)(>)', 'g');
			input = input && input.replace(/\n/g, ' ').replace(/  /g, ' ')
			var svgOpenTag = svgHeadRx.exec(input)[0];
			svgOpenTag = svgOpenTag.replace(new RegExp('(<svg )|>', 'g'), '');
			var attrsSrc = svgOpenTag.split('" ');
			var attrsObj = {};

			attrsSrc = attrsSrc.map(x => x.trim())

			attrsSrc.forEach(function (attrStr) {
				var attrArray = attrStr.split('=');

				var attrName = attrArray[0];
				var attrVal = attrArray[1];
				attrName = attrName.replace(' ', '')

				attrVal = attrVal.replace(new RegExp('["]', 'g'), '');
				attrsObj[attrName] = attrVal;
			});
			return attrsObj;
		},
		changeThickness(e) {
			console.log('CHANGING THICKNESSSS TO .icon-preview-holder svg ", e', e.target.value)
			if (document.querySelector('.icon-preview-holder > svg')) {
				this.thickness = e.target.value
				document.querySelector('.icon-preview-holder > svg').style = `stroke-width:${e.target.value}px`
			}
		},
		closeAndReset() {
			this.$store.commit('CLOSE_LIBRARY')
			this.colorize = '#000000'
			this.currentIcon = ''
			this.currentIconFolder = '__all__'
			this.scrollToTop()
		},
		scrollToTop() {
			if (this.$refs.scrollicons)
				return this.$refs.scrollicons.$el.scrollTo(0, 0)
		},
		onClose() {
			document.querySelectorAll('.highlight-file').forEach(x => x.classList.remove('highlight-file'))
			this.$store.commit('CLOSE_LIBRARY')
		},
		changeFolder(newFolder) {
			this.currentFolder = newFolder
		},
		async optionClicked(id, folder) {
			console.log('aaaaaa', id, folder)
			this.changeFolder(folder)

			await document.querySelectorAll('.highlight-file').forEach(x => x.classList.remove('highlight-file'))
			if (document.querySelector(`[data-file-id="${id}"]`)) {
				document.querySelector(`[data-file-id="${id}"]`).classList.add('highlight-file')
			}
		},
		async createSVG(icon) {
			let color = this.colorize.replace('#', '')
			let stroke = this.thickness
			let url = `${this.serviceURL}/create-svg?path=${icon.path}&token=tok_2618526272476&color=${color}&thickness=${stroke}`
			this.$store.commit('LOAD', true)
			let { data } = await axios.get(url)
			this.$store.commit('LOAD', false)
			if (data && data.extPath) {
				let { extPath } = data
				icon.url = extPath
				return icon
			} else {
				return {}
			}
		},
		async transformIcon(icon) {
			if (this.dialog.url && (this.dialog.colorized || this.dialog.stroke)) {
				return await this.createSVG(icon)
			}

			if (!icon.data.includes('<svg')) return icon

			icon = JSON.parse(JSON.stringify(icon))

			if (!icon.data.includes('alt=')) {
				icon.data = icon.data.replace('<svg', `<svg alt="${icon.name}" `)
			}
			let style = ''
			if (this.dialog.colorized) {
				style += `color:${this.colorize};`
			}
			if (this.isStroke && this.thickness) {
				style += `stroke-width:${this.thickness}px;`
			}

			icon.data = icon.data.replace('<svg', `<svg style="${style}" `)
			return icon
		},
		async submit() {

			let sendObj = await this.transformIcon(this.currentIcon)
			if (!Object.keys(sendObj).length) return console.error('SOMETHING HAPPEND while creating svg')
			this.dialog.callback(sendObj)
			this.closeAndReset()
		},
		changeCurrentFolder(value) {
			if (this.currentFolder == value) {
				console.log('SAME FOLDERRR')
			} else {
				console.log(`${value} FOLDER SELECTED`)
				this.currentFolder = value
				this.showImage = false
				this.checkbox = ''
			}

		},
		deleteImage(image) {

			let self = this
			this.$confirm({
				title: "Delete",
				content: h => <div>Do you want to delete this file?</div>,
				okText: 'Delete',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				onOk() {

					self.$api.delete(`/upload/files/${image.id}?instance=${self.instance.id}`).then(({ data }) => {
						console.log('FILE MANAGER DELETED A FILE ', data)
						let index = self.currentFiles.findIndex(x => x.id == image.id)

						self.currentFiles.splice(index, 1)
						self.showImage = false
						self.checkbox = ''
					}).catch(err => {
						self.$message.error(self.$err(err))
					})
				},
				onCancel() {
					console.log('Cancel')
				}
			})
		},
		copyLink() {
			var copyText = document.getElementById("myInput");

			copyText.select();
			copyText.setSelectionRange(0, copyText.value.length)
			document.execCommand("copy");
			this.$message.warning('Link Copied!')
		},
		shareImage(item) {
			this.link = true
			this.shareLink = item.url
		},
		handleSearch(input) {
			if (input && input.length > 2) {
				this.searchResult = Object.values(this.searchFiles).filter(({ name }) => {
					name = name.toLowerCase()
					input = input.toLowerCase()
					return name.includes(input)
				})
			} else {
				return this.searchResult = []
			}
		},
		selectedImage(checked, image) {
			if (checked == true) {
				this.showImage = true
				this.checkbox = image.icon
				this.submitImage = image.icon
			} else {
				this.checkbox = ''
				this.showImage = false
			}

		},
		filterFiles(file) {
			let filter = this.dialog.type
			let filterString = ''
			if (filter === 'any' || filter === 'all') return true
			else if (filter === 'images') filterString = '.jpg,.jpeg,.png,.gif,.webp,.svg'
			else if (filter === 'docs') filterString = '.pdf,.doc,.dovx,.gif,.webp,.svg'
			else if (filter === 'pdf') filterString = `.${filter}`
			return filterString.includes(file.ext.toLowerCase())
		},
		selectItem(icon) {
			console.log('ICON SELECTED', icon)
			this.showImage = true
			this.selectImage = icon

		},
		changeFolder(key) {
			this.currentFolder = key
		},
		UPLOADED_FILES(state, data) {

			let folder = data[0].folder
			if (!this.files[folder]) return this.files.folder = data

			let oldFiles = state.files[folder]
			data = [...oldFiles, ...data]

			return Vue.set(state.files, folder, data)

		},
		previewFile(file) {
			console.log('FILEEEEEEE', file)
			let type = null
			if (file.mime.includes('image')) {
				type = 'image'
			} else if (file.mime.includes('video')) {
				type = 'video'
			}
			if (type === null) return window.open(file.url);
			this.previewImage = {
				type,
				url: file.url,
				show: true
			}
		},
		deleteFile(e) {
			console.log('DELETING ', e)
			this.$api.delete(`/upload/files/${e.id}?instance=${this.instance.id}`).then(({ data }) => {
				console.log('FILE MANAGER DELETED A FILE ', data)
				this.$store.commit('DELETE_FILE', data)
			}).catch(err => {
				this.$message.error('something went wrong')
			})
		},
		FILE_IT(data) {
			if (!data['/']) data['/'] = []
			this.files = data
			this.currentFolder = '/'
		},
	},
	created() {
		window.axios = axios
	}

}
</script>
<style scoped>
.iconDisplay {
	font-size: 75px;
	margin-top: -37.5px;
	position: absolute;
	top: 50%;
	margin-left: -37.5px;
	left: 50%;
}

.folder-name {
	line-height: 40px;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
}

.folder-name:hover {
	background-color: var(--off-white-dark);
}

.show-options {
	z-index: 100
}
</style>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
.color-picker-absolute {
	position: absolute;
	right: 20px;
	top: 10px;
}

.icon-preview-holder {
	>svg {
		width: 50%;
		height: 50%;
	}
}

.imageScroll .ps__thumb-x {
	display: none;
}

.hive-cat-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px;
}

.app-store-tabs {
	.ant-tabs-bar {
		background: white;
		padding: 10px 100px 20px
	}
}

.file-list-preview {
	.ant-card {
		position: relative;

		&:hover {
			[aria-label="icon: close"] {
				opacity: 1;
				transform: translate3d(-50%, 0, 0);
			}

			.ant-card-body {
				background: var(--primary-vl);
			}
		}
	}

	.ant-card-body {
		padding: 10px 5px 8px;
	}

	.ant-card-meta-title {
		text-align: center;
		font-size: 12px;
	}

	[aria-label="icon: close"] {
		opacity: 0;
		transition: opacity .3s ease-out, transform .3s ease-out;
		;
		position: absolute;
		left: 50%;
		top: 25px;
		transform: translate3d(-50%, -70%, 0);
		background: white;
		padding: 5px;
		border-radius: 50%;
		cursor: pointer;
		box-shadow: 0 2px 5px rgba(50, 50, 50, 0.5);

		&:hover {
			background: var(--primary);
			color: white;
		}
	}
}

.list-item {
	display: inline-block;
	border: 1px solid var(--off-white-dark);
	padding: 10px;
}

.list-enter-active,
.list-leave-active {
	transition: all .5s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below version 2.1.8 */
	{
	opacity: 0;
	transform: scale(0);
}

.file-selector-box {
	.ant-modal-body {

		// padding:0;
		>div {
			// padding:24px;
		}

		// padding: 24px;
		// font-size: 14px;
		// line-height: 1.5;
		// word-wrap: break-word;
	}
}

.files-select-grid {
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	grid-gap: 20px;

	>div {
		grid-column: span 1;
	}

	.itemContent {
		height: 100px;
	}
}
</style>
<style>
@-webkit-keyframes blackWhite {
	0% {
		border-color: var(--orange);
	}

	50% {
		border-color: var(--orange);
	}

	51% {
		border-color: white;
	}

	100% {
		border-color: white;
	}
}

.highlight-file {
	height: 150px;
	border-color: white;
	-webkit-animation-name: blackWhite;
	/* -webkit-animation-iteration-count: infinite;   */
	-webkit-animation-duration: 5s;
}

/*
.highlight-file {
    height: 100px;
    border-color: var(--orange);

}    */
/* .highlight-file:active {
    height: 100px;
    border-color:white;
    transition: border-color .3s ease;
}    */
/* .highlight-file{
    border:none;
    border-color:white;

} */
</style>
