<template>
	<!-- <a-modal class="widget-styles-color" :title="null" :footer="null" :closable="false" :bodyStyle="{padding:0}" :visible="data.show" > -->
	<a-popover trigger="click" v-model="show">

		<div style="display: flex" slot="content">

			<div style="display: flex; align-items: center">
				<div :style="{
					background: typeof color === 'string' ? color : color.hex,
					width: '10px',
				}" />

				<Material v-model="color" class="custom-slider" style="height: auto" />
			</div>

			<div style="padding: 30px">
				<Slider v-model="color" style="max-width: 290px; margin-top: 5px" />

				<div role="group" class="vc-slider-swatches" style="margin-top: 10px">
					<div @click="setColor('#FFFFFF')" aria-label="color:#FFFF00" role="button" class="vc-slider-swatch">
						<div class="vc-slider-swatch-picker"
							:class="isActive('#FFFFFF') ? 'vc-slider-swatch-picker--active' : ''"
							style="background: #ffffff; box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3);">
						</div>
					</div>
					<div @click="setColor('#DDDDDD')" aria-label="color:#FFFF00" role="button" class="vc-slider-swatch">
						<div class="vc-slider-swatch-picker" :class="isActive('#DDDDDD') ? 'vc-slider-swatch-picker--active' : ''
							" style="
                        background: #dddddd;
                        box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3);
                    "></div>
					</div>
					<div @click="setColor('#AAAAAA')" aria-label="color:#FFFF00" role="button" class="vc-slider-swatch">
						<div class="vc-slider-swatch-picker" :class="isActive('#AAAAAA') ? 'vc-slider-swatch-picker--active' : ''
							" style="
                        background: #aaaaaa;
                        box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3);
                    "></div>
					</div>
					<div @click="setColor('#666666')" aria-label="color:#FFFF00" role="button" class="vc-slider-swatch">
						<div class="vc-slider-swatch-picker" :class="isActive('#666666') ? 'vc-slider-swatch-picker--active' : ''
							" style="
                        background: #666666;
                        box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3);
                    "></div>
					</div>
					<div @click="setColor('#000000')" aria-label="color:#FFFF00" role="button" class="vc-slider-swatch">
						<div class="vc-slider-swatch-picker" :class="isActive('#000000') ? 'vc-slider-swatch-picker--active' : ''
							" style="
                        background: #000000;
                        box-shadow: 0 2px 5px rgba(100, 100, 100, 0.3);
                    "></div>
					</div>
				</div>

				<a-divider>{{ recentText ? recentText : `Recent Colors` }}</a-divider>

				<!-- <Compact v-model="color" /> -->

				<div role="group" class="vc-slider-swatches" style="margin-top: 10px">
					<div v-for="(clr, clrI) in recentColors" :key="`recentColor${clr}${clrI}`" @click="setColor(clr)"
						:aria-label="`color:${clr}`" role="button" class="vc-slider-swatch">
						<div class="vc-slider-swatch-picker"
							:class="isActive(clr) ? 'vc-slider-swatch-picker--active' : ''"
							:style="`background:${clr} ; box-shadow:0 2px 5px rgba(100,100,100,0.3)`"></div>
					</div>
				</div>
			</div>
		</div>

		<div :class="{ 'box-style-color-picker': boxStyle }">

			<a-avatar :style="{
				borderRadius: boxStyle ? '0' : '50%',
				background: typeof color === 'string' ? color : color.hex,
				border: '1px solid #eee',
				boxShadow: '1px 2px 5px rgba(200,200,200,0.5)',
			}" class="bordered-avatar" />

			<span class="uppercase" style="text-transform:uppercase;" v-if="boxStyle">
				{{ typeof color === 'string' ? color : color.hex ? color.hex : '' }}
			</span>

		</div>

	</a-popover>

	<!-- </a-modal> -->
</template>

<script>
import { Slider, Material, Compact } from "vue-color";
export default {
	components: { Slider, Material, Compact },
	props: ["value", "box-style", 'defaults', 'recent-text'],
	data() {
		return {
			color: this.value,
			show: false,
			rcnt: ["#FFFFFF", "#DDDDDD", "#000000"],
		};
	},
	watch: {
		color(val) {
			if (this.color && this.color.hex) this.$emit("input", this.color.hex);
		},
		show(val) {
			// if (val) this.color = {hex:this.value}
			if (val) this.color = this.value;
		},
	},
	computed: {
		recentColors() {
			let clrs = this.$store.state.appData && this.$store.state.appData.cson && this.$store.state.appData.cson.colors || [];
			clrs = clrs.filter((x, xI) => xI < 10);
			let defaults = this.rcnt
			if (this.defaults && this.defaults.length) defaults = this.defaults
			if (clrs.length <= 7) clrs = [...clrs, ...defaults];
			return clrs;
		},
	},
	methods: {
		setColor(color) {
			this.color = { hex: color };
		},
		isActive(clr) {
			let color = this.color;
			if (typeof color !== "string") color = color.hex;

			color = color.toLowerCase().trim();
			clr = clr.toLowerCase().trim();

			return clr === color;
		},
		okColor() {
			let data = this.data;
			if (data && typeof data.callback === "function") {
				data.callback(this.color);
			} else {
				return this.$emit("ok", this.color);
			}
		},
	},
	created() { },
};
</script>

<style lang="scss" scoped>
.box-style-color-picker {
	width: 94px;
	border: 1px solid #ccc;
	display: inline-flex;
	align-items: center;
	padding: 2px 5px;
	gap: 1ch;

	.ant-avatar {
		width: 13px;
		height: 13px;
	}
}
</style>
